import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { Chart, registerables } from 'chart.js';
import { darken } from 'polished';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';

import shipIconSource from '../../images/icons/ship-bg.svg';
import draftIconSource from '../../images/icons/draft-icon-map.svg';
import { externalTooltipHandler } from './graphHelpers';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${props => (props.inModal ? null : props.theme.fx.box_shadow)};
  border-radius: ${props => (props.inModal ? null : props.theme.style.border_radius)};
  border: ${props => (props.inModal ? null : '1px solid transparent')};
  padding: 0 12px;
  margin-bottom: 14px;

  .ant-select-show-arrow {
    height: 24px !important;
  }
`;

const BarContainer = styled.div`
  height: ${props => (props.column1 ? 'calc(45vh)' : 'calc(25vh)')};
  max-height: ${props => (props.column1 ? '500px' : '400px')};

  ${props => {
    if (props.extraSpace) {
      return `
        max-height: 500px;
        height: calc(100vh / 3);
      `;
    }
  }}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-bottom: 12px;
  margin-left: 12px;
  padding-right: 4px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
`;

const EndOfFirstRow = styled.div`
  display: flex;
  margin-top: 2px;
`;

const cursorLinePlugin = {
  afterDatasetsDraw: chart => {
    if (chart.tooltip?._active?.length) {
      let x = chart.tooltip._active[0].element.x;
      let yAxis = chart.scales.y;
      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#4a4a4a';
      ctx.stroke();
      ctx.restore();
    }
  },
};

const LineWithTimeGraph = ({ portcallId, mmsi, inModal = false, ...props }) => {
  const buildQueryParams = () => {
    if (portcallId) {
      return {
        ...props.data_source.parameters,
        port_call_id: portcallId,
      };
    } else {
      return {
        ...props.data_source.parameters,
        mmsi: mmsi,
      };
    }
  };

  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { loading, data, error } = useApi('get', props.data_source.url, buildQueryParams());

  let dataList = error || !data ? [] : data.data;
  const auxData = error || !data ? {} : data.aux_data;

  let destinations = [dataList[0]];
  let lastDestination = dataList[0]?.ais_destination;

  for (let i = 1; i < dataList.length; i++) {
    if (dataList[i].ais_destination !== lastDestination) {
      lastDestination = dataList[i].ais_destination;
      destinations.push(dataList[i]);
    } else if (i === dataList.length - 1) {
      destinations.push(dataList[i]);
    }
  }

  const legendObject = props.legend;
  const legendKeys = Object.keys(legendObject) || [];

  const shipIcon = new Image(24, 24);
  shipIcon.src = shipIconSource;

  const draftIcon = new Image(24, 24);
  draftIcon.src = draftIconSource;

  const getYAxisTitle = () => {
    let ytitle = '';
    if (dataList.length > 0 && Object.keys(dataList[0]).includes('draft')) {
      ytitle += t('Meters') + '   |   ';
    }

    ytitle += t(props['y-axis']?.title) || '';

    return ytitle;
  };

  const lastOfDataList = dataList.slice(-1);
  const firstOfDataList = dataList.slice(1);

  let timespan = 48;

  if (dataList && dataList.length > 1) {
    timespan = dayjs.duration(dayjs(lastOfDataList[0].time).diff(dayjs(firstOfDataList[0].time))).asHours();
  }

  let datasets = [];
  if (auxData?.timestamps && Object.keys(auxData.timestamps).length > 0) {
    datasets.push({
      label: t('Timestamps'),
      lineAtIndex:
        auxData?.timestamps && Object.keys(auxData.timestamps).length > 0
          ? Object.keys(auxData.timestamps).map(t => auxData.timestamps[t])
          : [],
      data:
        auxData?.timestamps && Object.keys(auxData.timestamps).length > 0
          ? Object.keys(auxData.timestamps).map(key => {
            let value = 0;
            if (dataList && dataList.length > 0) {
              let index = dataList.findIndex(o => {
                return o.timestamp >= auxData.timestamps[key].timestamp;
              });
              let prev = dataList[index - 1];
              let next = dataList[index];
              if (prev && (prev.speed || prev.speed === 0) && next && (next.speed || next.speed === 0)) {
                let slope = (next.speed - prev.speed) / (next.timestamp - prev.timestamp);
                value = prev.speed + (auxData.timestamps[key].timestamp - prev.timestamp) * slope;
              }
            }
            return { y: value, x: dayjs(auxData.timestamps[key].time) };
          })
          : [],
      backgroundColor: '#81C784',
      borderColor: '#81C784',
      pointBackgroundColor: '#81C784',
      pointBorderColor: '#ffffff',
      datalabels: {
        align: 'end',
        anchor: 'end',
      },
      pointStyle: 'circle',
      pointRadius: 4,
      pointBorderWidth: 2,
      borderWidth: 0,
      pointHitRadius: 1,
      order: 2,
    });
  }

  if (dataList.length > 0 && Object.keys(dataList[0]).includes('draft')) {
    datasets.push({
      label: t('Draft'),
      data: dataList.map(f => {
        return { y: -f.draft, x: dayjs(f.time) };
      }),
      backgroundColor: '#e6efff',
      borderColor: darken(0.2, '#e6efff'),
      pointBackgroundColor: darken(0.2, '#e6efff'),
      pointBorderColor: darken(0.2, '#e6efff'),
      fill: true,
      datalabels: {
        display: false,
      },
      pointStyle: 'circle',
      pointRadius: 1,
      borderWidth: 3,
      pointBorderWidth: 0,
      pointHitRadius: 1,
      order: 3,
    });
  }

  const dataOptions = {
    type: 'line',
    labels: [...dataList.map(f => dayjs(f.time).toDate())],
    datasets: [
      ...legendKeys.map(key => {
        return {
          label: legendObject[key].title,
          data: dataList.map(f => {
            return { y: f[key], x: dayjs(f.time).toDate() };
          }),
          backgroundColor: darken(0.2, legendObject[key].color),
          borderColor: darken(0.2, legendObject[key].color),
          pointBackgroundColor: darken(0.2, legendObject[key].color),
          pointBorderColor: darken(0.2, legendObject[key].color),
          datalabels: {
            align: 'end',
            anchor: 'end',
          },
          pointStyle: 'circle',
          pointRadius: 1,
          borderWidth: 3,
          pointBorderWidth: 0,
          pointHitRadius: 1,
          order: 3,
        };
      }),
      ...datasets,
      {
        label: t('Latest speed'),
        data: lastOfDataList.map(f => {
          return { y: f.speed, x: dayjs(f.time) };
        }),
        backgroundColor: darken(0.2, legendObject.speed.color),
        borderColor: darken(0.2, legendObject.speed.color),
        pointBackgroundColor: darken(0.2, legendObject.speed.color),
        pointBorderColor: '#ffffff',
        datalabels: {
          align: 'end',
          anchor: 'end',
        },
        pointStyle: shipIcon,
        pointRadius: 6,
        borderWidth: 3,
        pointBorderWidth: 2,
        pointHitRadius: 1,
        order: 1,
      },
    ],
  };

  let timeMin = null;
  let timeMax = null;

  if (dataList && dataList.length > 0) {
    const diff = dayjs(dataList[dataList.length - 1].time).diff(dataList[0].time);
    if (diff > 0) {
      timeMin = dayjs(dataList[0].time).add(diff * -0.1, 'milliseconds').toDate();
      timeMax = dayjs(dataList[dataList.length - 1].time).add(diff * 0.2, 'milliseconds').toDate();
    }
  }

  const { innerWidth } = window;

  const options = {
    interaction: {
      axis: 'x',
      mode: 'x',
      intersect: false,
    },
    clip: 10,
    cubicInterpolationMode: 'monotone',
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 30,
        bottom: 5,
        top: 25,
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [3, 3],
        },
        display: true,
        suggestedMin: 0,
        suggestedMax: 30,
        title: {
          text: getYAxisTitle(),
          display: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
        min: timeMin,
        max: timeMax,
        type: 'time',
        time: {
          displayFormats: {
            day: 'DD.MM',
            hour: 'DD.MM HH:mm',
            minute: 'DD.MM HH:mm',
          },
          unit: timespan >= 48 ? 'day' : 'hour',
          unitStepSize: timespan >= 48 ? 1 : 4,
        },
        ticks: {
          maxTicksLimit: innerWidth < 500 ? 10 : 30,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: 'myCustomPositioner',
        external: e => externalTooltipHandler(e, t),
        callbacks: {
          label: function(context) {
            if (context.dataset.label === t('Timestamps')) {
              return (
                t(context.dataset.lineAtIndex[context.dataIndex].label) +
                ': ' +
                dayjs(context.dataset.lineAtIndex[context.dataIndex].value).format('DD.MM.YYYY HH:mm')
              );
            } else if (context.dataset.label === t('Draft')) {
              return t(context.dataset.label) + ': ' + context.parsed.y * -1 + ' ' + t('meters');
            } else {
              return t(context.dataset.label) + ': ' + context.parsed.y + ' ' + props['y-axis']?.title.toLowerCase();
            }
          },
          title: function(context) {
            return context.map(c => dayjs(c.parsed.x).format('DD.MM.YYYY HH:mm'));
          },
        },
      },
      legend: {
        title: {
          padding: {
            right: 5,
          },
        },
        labels: {
          boxWidth: 20,
          boxHeight: 5,
          usePointStyle: true,
          generateLabels: chart => {
            return chart.data.datasets.map((set, i) => {
              return {
                pointStyle:
                  set.label === t('Latest speed') ? shipIcon : set.label === t('Draft') ? draftIcon : 'circle',
                datasetIndex: i,
                text: set.label + '  ',
                key: set.label,
                fillStyle: set.backgroundColor,
                strokeStyle: set.backgroundColor,
                hidden: !chart.isDatasetVisible(i),
              };
            });
          },
        },
        position: 'bottom',
      },
      datalabels: {
        display: function(context) {
          return context.dataIndex === dataList.length - 1;
        },
        backgroundColor: '#f6f6f6',
        borderColor: '#dbdbdb',
        borderWidth: 1,
        borderRadius: 4,
        color: 'black',
        font: {
          weight: 'normal',
        },
        formatter: function(value) {
          return t('Speed') + ': ' + value.y + ' ' + props['y-axis']?.title.toLowerCase();
        },
        padding: 6,
        offset: 12,
      },
    },
  };

  const backgroundColorPlugin = {
    beforeDatasetsDraw: chart => {
      const {
        ctx,
        chartArea: { left, width },
        scales: { y },
      } = chart;

      if (y.min < 0) {
        ctx.beginPath();
        ctx.moveTo(left - 8, y.getPixelForValue(0));
        ctx.lineTo(left + width, y.getPixelForValue(0));
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#c8c8c8';
        ctx.stroke();
      }
    },
    beforeDraw: chart => {
      const {
        ctx,
        chartArea: { top, height },
        scales: { x },
      } = chart;
      const bgcolors = ['#f8f8f8', '#eeeeee'];

      for (let i = 0; i < destinations.length - 1; i++) {
        let start = destinations[i].time;
        let end = destinations[i + 1].time;

        ctx.fillStyle = bgcolors[i % 2];
        ctx.fillRect(
          x.getPixelForValue(dayjs(start)),
          top,
          x.getPixelForValue(dayjs(end)) - x.getPixelForValue(dayjs(dayjs(start))),
          height
        );
      }

      let bottomText = false;

      for (let i = 0; i < destinations.length - 1; i++) {
        let start = destinations[i].time;
        let end = destinations[i + 1].time;

        let keepTop = true;

        if (
          x.getPixelForValue(dayjs(end)) - x.getPixelForValue(dayjs(dayjs(start))) <= 20 &&
          i < destinations.length - 2
        ) {
          keepTop = false;
        }

        if (destinations[i].ais_destination) {
          ctx.fillStyle = '#4a4a4a';
          ctx.font = 'normal 11px sans-serif';
          ctx.fillText(
            destinations[i].ais_destination,
            x.getPixelForValue(dayjs(start)),
            top + (!keepTop && bottomText ? 24 : keepTop ? -8 : 10),
            keepTop && i < destinations.length - 2
              ? x.getPixelForValue(dayjs(end)) - x.getPixelForValue(dayjs(dayjs(start)))
              : undefined
          );
        }

        if (!keepTop) {
          bottomText = true;
        }
      }
    },
  };

  return (
    <Container inModal={inModal}>
      <Graph>
        {!inModal ? (
          <HeaderRow>
            <Header>{t('Speed')}</Header>
            <EndOfFirstRow>
              {loading && (
                <MiniLoader>
                  <Spin size="small" />
                </MiniLoader>
              )}
            </EndOfFirstRow>
          </HeaderRow>
        ) : null}

        {loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : dataList && dataList.length > 0 ? (
          <BarContainer column1={props.column1}>
            <Line
              data={dataOptions}
              options={options}
              plugins={[ChartDataLabels, cursorLinePlugin, backgroundColorPlugin]}
            />
          </BarContainer>
        ) : (
          <NoData>{t('No speed data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default LineWithTimeGraph;
